<template>
<div>
  <div
    class="wrapper d-flex pa-3"
    :class="active ? 'active' : ''"
    @click="$emit('click')"
  >
    <StatusAvatar :userId="thread.user.id" :size="30" class="mr-2" />
    <div class="d-flex flex-column">
      <span class="font-weight-black">{{
        thread.user.fullName | trimText(30)
      }}</span>
      <span class="caption">{{ companyName }}</span>
      <span class="caption mt-5">{{
        thread.conversation.threadType
          | getDescription($store.getters.enums.conversationThreadType)
      }}</span>
      <span>
        {{ thread.conversation.lastMessage | trimText(30) }}
      </span>
    </div>
    <div class="d-flex flex-column text-right ml-auto">
      <span class="caption">{{
        thread.conversation.lastMessageTime | dateFormat('DD.MM.YYYY')
      }}</span>
      <span class="caption">{{
        thread.conversation.lastMessageTime | dateFormat('HH:mm')
      }}</span>
    </div>
  </div>
  <v-divider class="mx-4" />
</div>
</template>
<script>
import StatusAvatar from '@/components/StatusAvatar.vue'
export default {
  components: { StatusAvatar },
  name: 'ThreadOnList',
  props: {
    active: Boolean,
    thread: Object
  },
  computed: {
    companyName () {
      if (!this.thread?.user?.company) {
        return ''
      }
      const company = this.thread.user.company
      return `${company.name} - ${company.displayId}`
    }
  }
}
</script>
<style scoped>
.wrapper.active {
  background: rgb(230, 230, 230);
}
.wrapper:hover {
  background: rgb(240, 240, 240);
  cursor: pointer;
}
</style>
