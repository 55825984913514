<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex flex-column">
        <label :for="id">{{ label }}</label>
        <v-text-field
          :id="id"
          v-model="dateModel"
          append-icon="$calendar"
          readonly
          outlined
          dense
          single-line
          hide-details="auto"
          :rules="rules"
          :error="error"
          :error-messages="errorMessages"
          v-bind="attrs"
          v-on="on"
          :clearable="resetButton"
        />
      </div>
    </template>
    <v-date-picker
      v-model="dateModel"
      :min="min"
      :max="max"
      no-title
      scrollable
      first-day-of-week="1"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: () => (moment().format('YYYY-MM-DD'))
    },
    label: {
      type: String,
      default: 'Date'
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([])
    },
    resetButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      menu: false,
      id: `date-${Math.floor(Math.random() * 1000)}`
    }
  },
  computed: {
    dateModel: {
      get () {
        return this.value && this.value.length > 0 ? moment(this.value).format('YYYY-MM-DD') : null
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    reset () {
      this.$refs.menu.save(null)
      this.$emit('change', this.dateModel)
      this.$emit('reset')
    },
    select () {
      this.$refs.menu.save(this.dateModel)
      this.$emit('change', this.dateModel)
    }
  }
}
</script>
